@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.logo {
  width: 150px; /* adjust as needed */
  height: auto; /* maintain aspect ratio */
}

.radix-themes {
  --default-font-family: "Menlo", "Consolas (Custom)", "Bitstream Vera Sans Mono", monospace,
    "Apple Color Emoji", "Segoe UI Emoji" !important;
}
